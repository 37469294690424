import React, { useEffect, useState, useReducer, useRef, useCallback } from "react";
import axios from "axios";
import QrCode from "qrcode.react";
import html2canvas from "html2canvas";
import downloadjs from "downloadjs";


export default function Qrcode() {
    const [url, setUrl] = useState({ qrcode: "", item: { imgUrl: "" } });
    const [value, setValue] = useState('');


    const mkReport = async () => {
        const e = document.querySelector('.report');
        var opts = {
            scale: 2, // 添加的scale 参数
            //canvas: canvas, //自定义 canvas
            //logging: true, //日志开关，便于查看html2canvas的内部执行流程
            width: e.width, //dom 原始宽度
            height: e.height,
            useCORS: true // 【重要】开启跨域配置
        };
        const canvas = await html2canvas(e, opts);
        const dataURL = canvas.toDataURL('image/png');
        downloadjs(dataURL, 'download.png', 'image/png');
    }

    return <div>
        <form onSubmit={(e) => {
            e.preventDefault();
            if (value !== "") {
                axios.post('https://apl.shopeach.com/getqrcode', { url: value })
                    .then(({ data }) => {
                        console.log(data);
                        setUrl(data);
                    })
            } else {
                setUrl('');
            }

        }}>
            <input className="left" onChange={(e) => {
                setValue(e.target.value);
                setUrl('');
            }} value={value} />
            <input type="submit" className="m-l" value="提交" />
            <input type="button" className="m-l" value="下载图片" onClick={mkReport} />

        </form>
        <QCode url={url} />
        <div className="imgs" ></div>
    </div >
}


function QCode(props) {
    const { qrcode, item } = props.url;
    console.log(qrcode);
    const coupon = ({ wlUnitPrice, commisionRatioWl }) => {
        const rea = wlUnitPrice * 1 * commisionRatioWl / 100;
        if (rea >= 2) {
            return Math.round(rea * 0.5);
        } else if (1 <= rea && rea < 2) {
            return Math.floor(rea);
        } else {
            return null;
        }
    }

    if (qrcode) {
        return < div className="report" >
            <img className="goods-img" alt="" src={item.imgUrl} />
            <p className="goods-name">{item.goodsName}</p>
            <span className="badge bg-danger left"> 京东{item.isJdSale === 1 ? "自营" : ""}</span><span className="price left">¥{item.unitPrice}</span>
            <p className="coupon-text left">券：</p><p className="coupon left">{coupon(item)}元</p>
            <p className="sm-text">扫码关注公众号  自动领优惠券</p>
            <QrCode className='canv' value={qrcode.url} renderAs="canvas" size={100} level="L" />

        </div>
    } else {
        return <></>
    }





}


