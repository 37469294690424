import React from "react";

// function List(aaa) {
//     console.log(aaa);
//     return <div>
//         <p>{aaa.match}</p>
//         <p>this is list</p>
//     </div>
// }


class List extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        console.log(this.parmas);
        return (
            <div>
                <p>{this.props.match}</p>
                <p>this is list</p>
            </div >
        )
    }

}

export default List;