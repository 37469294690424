import React from "react";
import { EditorState, AtomicBlockUtils, convertToRaw } from 'draft-js';



const TooBar = ({ editorState, onChange, onAddImage }) => {

    return (<div>
        <button onClick={(e) => {
            e.preventDefault();
            const t = onAddImage(editorState);
            console.log('addImage nnewEditorState3 ', convertToRaw(t.getCurrentContent()));
            onChange(t)
        }
        }>add image</button>
    </div >)
}

export default TooBar;


