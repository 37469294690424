import React, { useEffect, useRef, useMemo, useState } from 'react';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import { EditorState, convertToRaw, Entity, Modifier, linkify, blockRendererFn } from 'draft-js';
import 'draft-js/dist/Draft.css';
import createCustomPlugin from './pluginTmp';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';




const MyEditor = () => {

    const [plugins, InlineToolbar, Toolbar, addImage] = useMemo(() => {
        const inlineToolbarPlugin = createInlineToolbarPlugin();
        const linkifyPlugin = createLinkifyPlugin();
        const toolbarPlugin = createToolbarPlugin();
        const customPlugin = createCustomPlugin()
        return [[customPlugin, inlineToolbarPlugin, linkifyPlugin, toolbarPlugin], inlineToolbarPlugin.InlineToolbar, customPlugin.Toolbar, customPlugin.addImage];
    }, []);


    const [editorState, setEditorState] = useState(() =>
        createEditorStateWithText('text')
    );

    useEffect(() => {
        // fixing issue with SSR https://github.com/facebook/draft-js/issues/2332#issuecomment-761573306
        setEditorState(createEditorStateWithText('text'));
    }, []);

    const editor = useRef(Editor | null);

    const onChange = (value) => {
        setEditorState(value);
    };



    // const focus = () => {
    //     editor.current?.focus();
    // };




    return <>
        <div className="dx-editor">
            <Editor
                editorKey="MyEditor"
                editorState={editorState}
                onChange={onChange}
                plugins={plugins}
                ref={(element) => { editor.current = element }}
            />
            <Toolbar editorState={editorState}
                onAddImage={addImage}
                onChange={onChange}
            />
        </div>
        {/* <InlineToolbar /> */}
    </>
}


// const pluginTemp = (c) => {
//     console.log('plugins is ', c);
//     const handleKeyCommand = (c) => {
//         console.log('hangle', c);
//     }
//     return {
//         handleKeyCommand: handleKeyCommand
//     }
// }
// const Link = ({ entityKey, children }) => {
//     const { url } = Entity.get(entityKey).getData()

//     return (
//         <a target="_blank"
//             href={url} rel="noreferrer"
//         >
//             {children}
//         </a>
//     )
// }

// 创建插件的函数，因为插件可能可以接受不同的参数进行初始化。返回的对象就是一个 Draft.js 插件


export default MyEditor;