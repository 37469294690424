import TooBar from "./toolBarComponent";
import React, { ComponentType, ReactElement } from 'react';
import { EditorState, AtomicBlockUtils, ContentBlock, convertToRaw } from 'draft-js';

const createCustomPlugin = () => {
    const blockRendererFn = (block, { getEditorState }) => {
        console.log('createCustomPlugin', block);
        console.log('blockgetType', block.getEntityAt(0));
        const entity = block.getEntityAt(0);

        console.log('entity', block.getType());
        if (block.getType() === 'atomic') {
            const contentState = getEditorState().getCurrentContent();
            const entity = block.getEntityAt(0);

            if (!entity) return null;
            const type = contentState.getEntity(entity).getType();
            if (type === 'IMAGE' || type === 'image') {
                return {
                    component: Media,
                    editable: false,
                    props: { foo: "bbb" }
                };
            }
            return null;
        }

    };

    const Media = React.forwardRef((props, ref) => {

        const entity = props.contentState.getEntity(
            props.block.getEntityAt(0)
        );
        const { src } = entity.getData();
        const type = entity.getType();


        return <div onClick={(e) => console.log(e.childe)}>
            woshi media
            <img src={src} ref={ref} alt="tets" />
        </div>
    }
    )

    const customStyleMap = {
        'STRIKETHROUGH': {
            textDecoration: 'line-through',
        },
    };

    const toobar = (props) => {
        console.log('props', props);
        return <>
            <TooBar {...props} />
        </>
    }

    const addImage = (editorState) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'IMAGE',
            'IMMUTABLE',
            { src: 'https://lh3.googleusercontent.com/ogw/AOh-ky2hlbwCOz1YAuK5snOoNHOJH-1bR_oc3ZnSzfO4Zg=s64-c-mo' }
        );
        console.log('addImage nnewEditorState1 ', convertToRaw(contentState));

        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorState,
            entityKey,
            ' '
        );

        console.log('addImage nnewEditorState1 ', convertToRaw(newEditorState.getCurrentContent()));

        // return newEditorState;

        return EditorState.forceSelection(
            newEditorState,
            newEditorState.getCurrentContent().getSelectionAfter()
        );
    }


    return {
        blockRendererFn: blockRendererFn,
        customStyleMap: customStyleMap,
        Toolbar: toobar,
        addImage: addImage
    };
};


export default createCustomPlugin;