
import React, { useEffect, useState, useReducer, useRef } from "react";
import { useParams, useLocation, Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
const { toNumber } = require('lodash');


function About() {
    return (<>
        <div className=" container-sm ">
            <div className="row">
                <Outlet />
            </div>
        </div>
    </>)
};




function reducer(state, action) {
    switch (action.type) {
        case 'increment':
            return {
                curentPage: state.curentPage + 1,
                content: state.content,
                showcontent: false,
            };
        case 'decrement':
            return {
                curentPage: state.curentPage - 1,
                content: state.content,
                showcontent: false,
            };
        case 'show-content':
            return {
                content: action.content,
                showcontent: true,
                curentPage: state.curentPage
            };
        case 'hide-content':
            return {
                content: state.content,
                showcontent: false,
                curentPage: state.curentPage
            }
        default:
            throw new Error();
    }
}



function Post() {
    const location = useLocation();
    const { page } = useParams();
    const [posts, setPosts] = useState([]);
    const history = useNavigate()
    const initialState = {
        curentPage: page ? toNumber(page) : 1,
        showcontent: false,
        content: {
            post_id: '',
            post_title: '',
            post_content: ''
        }
    };
    const [cpost, dispatch] = useReducer(reducer, initialState);

    useEffect((pre) => {
        axios.get(`https://apl.shopeach.com/posts/${cpost.curentPage}`)
            .then(({ data }) => setPosts((preState) => {

                return [...new Set(data)];
            }))
        history(`/ps/${cpost.curentPage}`);
    }, [cpost.curentPage])




    return (
        <div className="row">
            <div className="col-6">
                <Pages curentPage={cpost.curentPage} dispatch={dispatch} />
                <ul>
                    {
                        posts.map(({ post_id, post_title, post_content }) => {
                            return (
                                <>
                                    <li key={post_id.toString()} onClick={() => {
                                        window.history.pushState("", '', `/p/${post_id}`);
                                        return dispatch({ type: 'show-content', content: { post_title, post_content } })
                                    }}>
                                        <h5 className="pp">{post_title}</h5>
                                    </li>
                                </>

                            )

                        })

                    }
                </ul  >
                <Pages curentPage={cpost.curentPage} dispatch={dispatch} />
                <View curentPage={cpost.curentPage} showcontent={cpost.showcontent} content={cpost.content} dispatch={dispatch} />
            </div >
        </div >
    )
}

function Pages(props) {
    const { curentPage, dispatch } = props;
    const disable = curentPage <= 1 ? true : false;
    return (
        <>
            <span><button disabled={disable} onClick={() => dispatch({ type: 'decrement' })} > 上一页</button> </span> <span className="page-current" >第{curentPage}页</span><span className="page-next" ><button onClick={() => dispatch({ type: 'increment' })} >  下一页</button></span >

        </>
    )

}

function View({ content, showcontent, dispatch, curentPage }) {
    const disabl = showcontent ? "view-show" : "view-hide";
    const view = useRef('view');

    useEffect(() => {
        view.current.scrollTop = 0;
    });
    return (
        <div ref={view} className={disabl} >
            <p className="f pp" onClick={() => {
                // window.history.go(-1);
                window.history.pushState("", '', `/ps/${curentPage}`);
                return dispatch({ type: "hide-content" })
            }}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className=" bi bi-x-circle-fill" viewBox="0 0 16 16"  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                </svg></p>
            <article className="mod cimg">
                <h5>{content.post_title}</h5>
                <div dangerouslySetInnerHTML={{ __html: content.post_content }} ></div>
            </article>
        </div >
    )
}

function Detail() {
    const { id } = useParams();
    const [post, setPost] = useState({})
    const [content, setContent] = useState('');
    useEffect(() => {
        axios.get(`https://apl.shopeach.com/post/${id}`)
            .then(({ data }) => {
                console.log(data[0]);
                setPost(data[0]);
                setContent(data[0].post_content);
            }
            )
    }, [])


    return (
        <article className="col-6 cimg" >
            <h3>{post.post_title}</h3>
            <div dangerouslySetInnerHTML={{ __html: content }} ></div>
        </article >
    )
}


export default About;
export { Post, Detail };
