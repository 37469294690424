import React, { useState, useCallback, useRef } from "react";
import { useMemo } from "react";

const set = new Set();

const Td = () => {
    const [isShow, setIsShow] = useState(false);
    const [listDate, setListDate] = useState([]);
    const [value, setValue] = useState();
    const [count, setCount] = useState(1);
    const changShow = (e) => {
        setIsShow(e)
    }
    const addItem = (v) => {
        setListDate((prevCount) => [v, ...prevCount]);
        console.log('addItem');
        // setIsShow(false);
    }

    const removeItem = ((i) => {
        console.log('hello2')
    });

    const expensive = useCallback(() => {
        console.log('compute');
        console.log('hello')
        let s = count;
        return s++;
    }, [count])
    set.add(expensive);

    return (
        <div>
            <h4>{count}-{value}-{expensive()}</h4>
            <h4>{set.size}         <kbd>Control</kbd>+<kbd>b</kbd>
            </h4>
            <input value={value} onChange={event => setValue(event.target.value)} />
            <button onClick={() => { setCount(count + 1) }}>C++</button>
            <Header showAdd={changShow} isShow={isShow} />
            <Addlist isShow={isShow} addItem={addItem} />
            {listDate.map((v, i) => {
                return (
                    <Item v={v} key={i} i={i} removeItem={removeItem} />
                )
            })}
        </div>
    )
}

const Item = (props) => {
    { console.log('active') }
    return (<li >
        <span> {props.i}{props.v}</span> <span><input type='submit' onClick={() => { props.removeItem(props.i); }} /></span>
    </li >)
}

const Addlist = (props) => {
    const input = useRef();
    const add = () => {
        props.addItem(input.current.value);
        input.current.value = '';
    }
    return (
        <div style={{ 'display': props.isShow ? 'block' : 'none' }}>
            <span><input ref={input} type="text" /></span><span><input type='submit' value='添加' onClick={add} /></span>
        </div>
    )
}

const Header = (props) => {
    return (
        <div>
            <button className="bnt" onClick={() => props.showAdd(!props.isShow)} >添加</button>
        </div>
    )
}

export default Td;