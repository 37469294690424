import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import About, { Post, Detail } from "./about";
import List from "./A";
import Qrcode from "./qrcode";
import MyEditor from './draft';
//import Edit from './slate';
import Td from './tolist';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
   <BrowserRouter>
      <Routes>
         <Route path="/" element={<App />} />
         <Route path="list" element={<List />} />
         <Route path="/p" element={<Post />} />
         <Route path="ps" element={<Post />} >
            <Route default element={<Post />} />
            <Route path=":page" element={<Post />} />
         </Route>
         <Route path="p" element={<About />} >
            <Route path=":id" element={<Detail />} />
         </Route>
         <Route path="/qr" element={<Qrcode />} />
         <Route path="/ed" element={<MyEditor />} />
         {/* <Route path="/sl" element={<Edit />} /> */}
         <Route path="/td" element={<Td />} />



      </Routes >
   </BrowserRouter >
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
