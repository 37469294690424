import React, { useState } from "react";
import "./App.css";
import axios from "axios"





//const url = "https://router.jd.com/api?v=1.0&method=jd.union.open.goods.promotiongoodsinfo.query&access_token=&app_key=41a3bbbab9cc162c5dc5c2565430b641&sign_method=md5&format=json&timestamp=2021-02-05 21:32:51&sign=DF0950B8AABEADDB5F0C855C6EC22481&param_json={‘skuIds’:‘8265738‘}"




function App() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <a className="navbar-brand" href="/">shop<span class="badge bg-danger text-light">each</span></a>
        </div>
      </nav>

      <main className="main">
        <section className="py-5 ">
          <div className="container text-center ">
            <h3 className="py-4 display-5 text-bold">Shop Today's Trending Deals and Save Big </h3>

            <form className="row justify-content-center">

              <div className="col-6">
                <input type="text" className="form-control" />
              </div>
              <div className="col-auto ">
                <input type="submit" className="btn btn-danger" value="Search" />
              </div>

            </form>


          </div>
        </section>

        <section className="section py-5">
          <div className="container">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
              <div className="col-sm-4 py-2">
                <div className="card ">
                  <img src="https://mediaservice.retailmenot.com/ws/mediagroup/EQRJWXCWG5FGBATNHYUOBJHAOY?width=300&height=150" />
                  <div className="card-body">
                    <h5 className="card-title"> sale </h5>
                    <p className="card-text">Up to 60% Off Orders + Free Shipping Over $35 </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 py-2">
                <div className="card ">
                  <img src="https://mediaservice.retailmenot.com/ws/mediagroup/372W4EC5HVBFVO23QU7TVAYGHU?width=300&height=150" />
                  <div className="card-body">
                    <h5 className="card-title">Sale</h5>
                    <p className="card-text"> Heattech, Ultra Light Down, Tops & Pants at $9.90</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 py-2">
                <div className="card ">
                  <img src="https://mediaservice.retailmenot.com/ws/mediagroup/BTHHXRBH2FALVJJX77IGLD6A6M?width=300&height=150" />

                  <div className="card-body">
                    <h5 className="card-title">Sale</h5>
                    <p className="card-text">20% Off For Students, Military Members And First Responders</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>

      <footer className="py-5 text-muted">
        <div className="container">
          <p className="mb-1"> © 2021 shopeach,Shop and Save with Coupons!</p>
          <p className="float-end mb-1">
            <a href="https://beian.miit.gov.cn/" >沪ICP备14026774号</a>
          </p>
        </div>
      </footer>
    </>
  );
}

export default App;
